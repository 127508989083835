import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/indexView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      name: 'home'
    },
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    meta: {
      name: 'about'
    },
    component: () => import('../views/AdoutView.vue'),
  },
  {
    path: '/solutions',
    name: 'solutions',
    meta: {
      name: 'solutions',
    },
    component: () => import('../views/SolutionsView.vue'),
  },
  {
    path: '/demand',
    name: 'demand',
    meta: {
      name: 'demand'
    },
    component: () => import('../views/DemandView.vue'),
  },
  {
    path: '/partners',
    name: 'partners',
    meta: {
      name: 'partners'
    },
    component: () => import('../views/PartnersView.vue'),
  },
  {
    path: '/submit',
    name: 'submit',
    meta: {
      name: 'submit'
    },
    component: () => import('../views/SubmitView.vue'),
  },
  {
    path: '/subscribe',
    name: 'subscribe',
    meta: {
      name: 'subscribe'
    },
    component: () => import('../views/SubScribeView.vue'),
  },
  {
    path: '/logdsp',
    name: 'logdsp',
    meta: {
      name: 'logdsp'
    },
    component: () => import('../views/logdspView.vue'),
  },
  {
    path: '/logssp',
    name: 'logssp',
    meta: {
      name: 'logssp'
    },
    component: () => import('../views/logsspView.vue'),
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to,from,next)=>{
	document.title = 'Younger World'
  next()
})

// if (to.meta) {
//   document.title = `${defaultSetting.title} - ${to.meta}`
// } else {
//   document.title = `${defaultSetting.title}`
// }
export default router
