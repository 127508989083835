<template>
	<div class="width" style="position: relative;height: 100vh;background-color: #8D5CD9;">
		<div :class="{ 'home': home, 'home2': true }" :style="{ 'height': home ? `${gao}px` : '' }">
			<HelloWorld @shift="shift" />

			<div @click="huad">
				<div style="position: relative;">
					<img class="show" src="../assets/images/dbg5.jpg" style="width: 100%;min-height: 800px;" alt="">
					<img class="show2" src="../assets/images/dbg5.jpg" style="width: 100%;height: 510px;" alt="">
					<div class="dbox" style="padding: 0 10px;position: absolute;top: 0;bottom: 0;left: 0;right: 0;margin: auto;height: 60%;text-align: center;">
						<div class="biaoti">Welcome to Adyounger <br> Simplified Advertising at Your Fingertips.</div>
						<div class="biaoti2">We assist brands and agencies inexecuting and enhancing highly effective advertising campaigns through captivating rich media creations and advanced targeting strategies.</div>
						<div class="biaoti3">Your gateway to top-notch advertising technology partnerships.</div>
						<div>
							<a href="https://c.adyounger.com/">
								<button class="biaoti4">GET STARTED</button>
							</a>
							<button class="biaoti5" @click="lxwm">GET IN TOUCH</button>
						</div>
					</div>
				</div>
				<div>
					<div>
						<div class="dbox boxto" :class="{ 'box': true, 'box_gd': opa1 }">
							<div class="title">Advertising, made easy.</div>
							<div class="content">
								For years, our commitment has been to assist our customers in discovering their ideal clientele.
							</div>
						</div>

						<div style="background: #EEF6FF;padding: 40px 0;" :class="{ 'box': true, 'box_gd': opa2 }">
							<div class="dbox box1 show">
								<div style="position: relative;">
									<div style="margin-bottom: 107px;">
										<img style="width: 48px;" src="../assets/images/douh.png" alt="">
										<img style="width: 48px;" src="../assets/images/douh.png" alt="">
									</div>
									<div style="position: absolute;top: 34px;font-size: 36px;font-weight: 600;">Unlocking business potential</div>
									<div style="margin-bottom: 28px;font-size: 18px;vertical-align: middle;">Powered by vast and long-accumulated data from hundreds of thousands of brands and millions of digital users. Allow our proficiency to discover the ideal clients for your business.</div>
								</div>
								<div><img style="width: 645px;" src="../assets/images/acfa9304801acb8b8d6e8e23cc5de36d3089a8ae167d4-XfotXm_fw1200.png" alt=""></div>
							</div>

							<div class="dbox show2" style="align-items: center;padding: 0 10px;">
								<div style="text-align: center;"><img style="width: 256px;" src="../assets/images/acfa9304801acb8b8d6e8e23cc5de36d3089a8ae167d4-XfotXm_fw1200.png" alt=""></div>
								<div>
									<div style="margin-bottom: 14px; font-size: 24px;font-weight: 600;width: 100%;text-align: center;">Unlocking business potential</div>
									<div style="margin-bottom: 28px;font-size: 16px;vertical-align: middle;text-align: center;">Powered by vast and long-accumulated data from hundreds of thousands of brands and millions of digital users. Allow our proficiency to discover the ideal clients for your business.</div>
								</div>
							</div>
						</div>
						
						<div style="padding: 40px 0;" :class="{ 'box': true, 'box_gd': opa3 }">
							<div class="dbox box1 show">
								<div><img style="margin-right: 30px;width: 645px;" src="../assets/images/2e44db8b58d2f4be40ad47504af80bee5ff5005911683-xChpJS_fw1200(2).png" alt=""></div>
								<div style="position: relative;">
									<div style="margin-bottom: 107px;">
										<img style="width: 48px;" src="../assets/images/douh.png" alt="">
										<img style="width: 48px;" src="../assets/images/douh.png" alt="">
									</div>
									<div style="position: absolute;top: 52px;font-size: 36px;font-weight: 600;line-height: 1;">Streamline your campaign management</div>
									<div style="margin-bottom: 28px;font-size: 18px;vertical-align: middle;">Efficiently manage your campaigns across millions of websites and mobile apps, all from one place, saving you time and effort.</div>
								</div>
							</div>
							<div class="dbox show2" style="padding: 0 10px;">
								<div style="text-align: center;"><img style="width: 256px;" src="../assets/images/2e44db8b58d2f4be40ad47504af80bee5ff5005911683-xChpJS_fw1200(2).png" alt=""></div>
								<div>
									<!-- style="position: relative;" <div style="margin-bottom: 107px;">
										<img style="width: 48px;" src="../assets/images/douh.png" alt="">
										<img style="width: 48px;" src="../assets/images/douh.png" alt="">
									</div>position: absolute;top: 52px; -->
									<div style="margin-bottom: 14px;font-size: 24px;font-weight: 600;text-align: center;">Streamline your campaign management</div>
									<div style="margin-bottom: 28px;font-size: 16px;text-align: center;">Efficiently manage your campaigns across millions of websites and mobile apps, all from one place, saving you time and effort.</div>
								</div>
							</div>
						</div>
						
						<div style="background: #EEF6FF;padding: 40px 0;" :class="{ 'box': true, 'box_gd': opa4 }">
							<div class="dbox box1 show">
								<div style="position: relative;">
									<div style="margin-bottom: 147px;">
										<img style="width: 48px;" src="../assets/images/douh.png" alt="">
										<img style="width: 48px;" src="../assets/images/douh.png" alt="">
									</div>
									<div style="position: absolute;top: 52px;font-size: 36px;font-weight: 600;line-height: 1;">Streamline Your path to effective campaigns and maximum returns campaign management</div>
									<div style="margin-bottom: 28px;font-size: 18px;vertical-align: middle;">If you aim to execute effective campaigns but face limitations in managing various platforms, consider ADYG’s managed services. We can function as an extension of your marketing team, providing you with a dedicated platform expert for collaboration. This partnership helps you and your team attain your marketing goals while maximizing campaign returns.</div>
								</div>
								<div><img style="width: 645px;" src="../assets/images/indexws.png" alt=""></div>
							</div>
							<div class="dbox show2" style="padding: 0 10px;">
								<div style="text-align: center;"><img style="width: 256px;" src="../assets/images/indexws.png" alt=""></div>
								<div>
									<!-- style="position: relative;" <div style="margin-bottom: 147px;">
										<img style="width: 48px;" src="../assets/images/douh.png" alt="">
										<img style="width: 48px;" src="../assets/images/douh.png" alt="">
									</div>position: absolute;top: 52px; -->
									<div style="margin-bottom: 14px;font-size: 24px;font-weight: 600;text-align: center;">Streamline Your path to effective campaigns and maximum returns campaign management</div>
									<div style="margin-bottom: 28px;font-size: 16px;text-align: center;">If you aim to execute effective campaigns but face limitations in managing various platforms, consider ADYG’s managed services. We can function as an extension of your marketing team, providing you with a dedicated platform expert for collaboration. This partnership helps you and your team attain your marketing goals while maximizing campaign returns.</div>
								</div>
							</div>
						</div>
						
						<div style="padding: 40px 0;" :class="{ 'box': true, 'box_gd': opa5 }">
							<div class="dbox box1 show">
								<div><img style="width: 645px;" src="../assets/images/indexkwz.png" alt=""></div>
								<div style="position: relative;">
									<div style="margin-bottom: 147px;">
										<img style="width: 48px;" src="../assets/images/douh.png" alt="">
										<img style="width: 48px;" src="../assets/images/douh.png" alt="">
									</div>
									<div style="position: absolute;top: 52px;font-size: 36px;font-weight: 600;line-height: 1;">Streamline your campaign Effortless campaign management for all budgets</div>
									<div style="margin-bottom: 28px;font-size: 18px;vertical-align: middle;">ADYG’s platform simplifies the process, allowing individuals to swiftly and effectively initiate and oversee campaigns, regardless of the budget.</div>
								</div>
							</div>
							<div class="dbox show2" style="padding: 0 10px;">
								<div style="text-align: center;"><img style="width: 256px;" src="../assets/images/indexkwz.png" alt=""></div>
								<div>
									<!--  style="position: relative;"<div style="margin-bottom: 147px;">
										<img style="width: 48px;" src="../assets/images/douh.png" alt="">
										<img style="width: 48px;" src="../assets/images/douh.png" alt="">
									</div>position: absolute;top: 52px; -->
									<div style="margin-bottom: 14px;font-size: 24px;font-weight: 600;text-align: center;">Streamline your campaign Effortless campaign management for all budgets</div>
									<div style="margin-bottom: 28px;font-size: 16px;text-align: center;">ADYG’s platform simplifies the process, allowing individuals to swiftly and effectively initiate and oversee campaigns, regardless of the budget.</div>
								</div>
							</div>
						</div>

						<div class="show" style="margin: 80px 0;text-align: center;font-size: 36px;font-weight: 600;">Ready to begin? <span style="color: #3F79FE">Sign up</span> for your free account today.</div>
						<div class="show2" style="padding: 0 10px;margin: 80px 0;text-align: center;font-size: 24px;font-weight: 600;">Ready to begin? <span style="color: #3F79FE">Sign up</span> for your free account today.</div>
					</div>
				</div>
			</div>
			<DottomView></DottomView>
		</div>



		
		<div :class="{ 'right_list': true, 'right_list2': home }">
			<div class="menu_box">
				<router-link to="/about">
					<div>
						<p style="color: black;font-size: 20px;font-weight: 500;color: #fff;">About Us</p>
						<div class="bor2"></div>
					</div>
				</router-link>
				<router-link to="/demand">
					<div>
						<p style="color: black;font-size: 20px;font-weight: 600;color: #fff;">Demand-Side Platform(DSP)
						</p>
						<div class="bor2"></div>
					</div>
				</router-link>
				<router-link to="/solutions">
					<div>
						<p style="color: black;font-size: 20px;font-weight: 600;color: #fff;">Supply-Side Platform(ssp)
						</p>
						<div class="bor2"></div>
					</div>
				</router-link>
				<router-link to="/partners">
					<div>
						<p style="color: black;font-size: 20px;font-weight: 600;color: #fff;">partners and cases</p>
						<div class="bor2"></div>
					</div>
				</router-link>
				<router-link to="/logdsp">
					<div>
						<p style="color: black;font-size: 20px;font-weight: 600;color: #fff;">Log In</p>
						<div class="bor2"></div>
					</div>
				</router-link>
				<router-link to="/submit">
					<div>
						<p style="color: black;font-size: 20px;font-weight: 600;color: #fff;">Contact Us</p>
						<div class="bor2"></div>
					</div>
				</router-link>
			</div>
		</div>


	
	</div>
</template>

<script setup>
import HelloWorld from '@/components/HelloWorld.vue'
import DottomView from '@/components/DottomView.vue'
import { ref, onMounted } from 'vue';
import { useRouter } from "vue-router"
const router = useRouter()
let opa1 = ref(false)
let opa2 = ref(false)
let opa3 = ref(false)
let opa4 = ref(false)
let opa5 = ref(false)
// let ht = ref(true)
// let ht2 = ref(true)
// let ht3 = ref(true)
// let home = ref(false)
// let gao = ref(0)
// let img = ref(false)
// var time1
// var time2
// var time3
// let img_biddings = ref([
// 	require('../assets/img_bidding_01.png'),
// 	require('../assets/img_bidding_02.png'),
// 	require('../assets/img_bidding_03.png'),
// 	require('../assets/img_bidding_04.png'),
// 	require('../assets/img_bidding_05.png'),
// 	require('../assets/img_bidding_06.png'),
// 	require('../assets/img_bidding_07.png'),
// 	require('../assets/img_bidding_08.png'),
// 	require('../assets/img_bidding_09.png'),
// 	require('../assets/img_bidding_10.png'),
// 	require('../assets/img_bidding_11.png'),
// 	require('../assets/img_bidding_12.png'),
// 	require('../assets/img_bidding_13.png'),
// 	require('../assets/img_bidding_14.png'),
// 	require('../assets/img_bidding_15.png'),
// 	require('../assets/img_bidding_16.png')
// ])
// let img_programs = ref([
// 	require('../assets/img_program_01.png'),
// 	require('../assets/img_program_02.png'),
// 	require('../assets/img_program_03.png'),
// 	require('../assets/img_program_04.png'),
// 	require('../assets/img_program_05.png'),
// 	require('../assets/img_program_06.png'),
// 	require('../assets/img_program_07.png'),
// 	require('../assets/img_program_08.png'),
// 	require('../assets/img_program_09.png'),
// 	require('../assets/img_program_10.png'),
// 	require('../assets/img_program_11.png'),
// 	require('../assets/img_program_12.png'),
// 	require('../assets/img_program_13.png'),
// 	require('../assets/img_program_14.png'),
// 	require('../assets/img_program_15.png'),
// 	require('../assets/img_program_16.png')
// ])
// let img_solutions = ref([
// 	require('../assets/img_solution_01.png'),
// 	require('../assets/img_solution_02.png'),
// 	require('../assets/img_solution_03.png'),
// 	require('../assets/img_solution_04.png'),
// 	require('../assets/img_solution_05.png'),
// 	require('../assets/img_solution_06.png'),
// 	require('../assets/img_solution_07.png'),
// 	require('../assets/img_solution_08.png'),
// 	require('../assets/img_solution_09.png'),
// 	require('../assets/img_solution_10.png'),
// 	require('../assets/img_solution_11.png'),
// 	require('../assets/img_solution_12.png'),
// 	require('../assets/img_solution_13.png'),
// 	require('../assets/img_solution_14.png'),
// 	require('../assets/img_solution_15.png'),
// 	require('../assets/img_solution_16.png')
// ])
// let img_bidding = ref('')
// let img_program = ref('')
// let img_solution = ref('')


window.addEventListener('scroll', scrollTop, true)
window.addEventListener('resize', widths, true)

onMounted(async () => {
	window.scrollTo(0, 0)
	scrollTop()
	widths()
});

function widths() {
	if (window.innerWidth <= 1500) {
		// img.value = true
		opa1.value = true
		opa2.value = true
		opa3.value = true
		opa4.value = true
		opa5.value = true
		// opa6.value = true
		// opa7.value = true
		// dsq()
		// dsq2()
		// dsq3()
	} else {
		// img.value = false
	}
}




// function huad() {
// 	home.value = false
// }
// function shift() {
// 	home.value = !home.value
// 	gao.value = window.innerHeight - 40
// }
// function dsq() {
// 	if (ht.value) {
// 		ht.value = false
// 		clearInterval(time1)
// 		var i = 0
// 		time1 = setInterval(function () {
// 			++i
// 			img_bidding.value = img_biddings.value[i]
// 			if (i >= 15) {
// 				clearInterval(time1)
// 			}
// 		}, 50)
// 	}
// }
// function dsq2() {
// 	if (ht2.value) {
// 		ht2.value = false
// 		clearInterval(time2)
// 		var i = 0
// 		time2 = setInterval(function () {
// 			++i
// 			img_program.value = img_programs.value[i]
// 			if (i >= 15) {
// 				clearInterval(time2)
// 			}
// 		}, 50)
// 	}
// }
// function dsq3() {
// 	if (ht3.value) {
// 		ht3.value = false
// 		clearInterval(time3)
// 		var i = 0
// 		time3 = setInterval(function () {
// 			++i
// 			img_solution.value = img_solutions.value[i]
// 			if (i >= 15) {
// 				clearInterval(time3)
// 			}
// 		}, 50)
// 	}
// }

function scrollTop() {
	if (window.pageYOffset >= 300) {
		opa1.value = true
		// home.value = false
	}
	if (window.pageYOffset >= 700) {
		opa2.value = true
	}
	if (window.pageYOffset >= 1500) {
		opa3.value = true
	}
	// if (window.pageYOffset >= 900) {
	// 	opa4.value = true
	// 	if (ht.value) {
	// 		dsq()
	// 	}
	// }
	// if (window.pageYOffset >= 1300) {
	// 	opa5.value = true
	// 	if (ht2.value) {
	// 		dsq2()
	// 	}
	// }
	// if (window.pageYOffset >= 1600) {
	// 	opa6.value = true
	// 	if (ht3.value) {
	// 		dsq3()
	// 	}
	// }
	if (window.pageYOffset >= 2100) {
		opa4.value = true
	}
	if (window.pageYOffset >= 2600) {
		opa5.value = true
	}
	// if (window.pageYOffset >= 2000) {
	// 	opa6.value = true
	// }
}

function lxwm() {
	router.push('/submit')
}

</script>

<style scoped>
.width{
	min-width: 1200px;
}
.show{
	display: block;
}
.show2{
	display: none;
}
.dbox {
	margin: 0 auto;
	max-width: 1200px;
	/* margin-top: 80px; */
}
.box1{
	display: flex;
	align-items: center;
}

.right_list {
	display: none;
}

.right_list2 {
	position: absolute;
	overflow-y: auto;
	height: 100%;
	right: 0;
	width: 73%;
	display: block;
}

.menu_box {
	position: absolute;
	top: 25%;
}

.bor2 {
	transition: all .3s;
	width: 0;
	height: 2px;
}

.menu_box div:hover>.bor2 {
	height: 2px;
	background-color: #fff;
	width: 100%;
}

.home2 {
	transition: all .3s;
	position: absolute;
	right: 0;
	width: 100%;
	background-color: #fff;
}

.home {
	transition: all .3s;
	position: absolute;
	right: 75%;
	margin: 20px 0;
	overflow: hidden;
}

.biaoti {
	font-size: 56px;
	color: #fff;
	font-weight: normal;
}

.biaoti2{
	margin: 58px 0 30px 0;
	font-size: 30px;
	color: #FFFFFF;
}

.biaoti3{
	margin-bottom: 70px;
	font-size: 30px;
	color: #FFFFFF;
}

.biaoti4{
	margin-right: 20px;
	padding: 15px 20px;
	border: 1px solid #FFFFFF;
	border-radius: 38px;
	background-color: #fff;
	color: #3F79FE;
	font-size: 28px;
	cursor: pointer;
}
.biaoti5{
	padding: 15px 20px;
	border: 1px solid #FFFFFF;
	border-radius: 38px;
	background-color: rgba(0, 0, 0, 0); 
	color: #FFFFFF;
	font-size: 28px;
	cursor: pointer;
}

.boxto{
	padding-top: 120px;
	padding-bottom: 104px;
}
.box {
	transition: all 1s;
	margin-top: 100px;
	opacity: 0;
}

.box_gd {
	margin-top: 0;
	opacity: 1;
}
.box2 {
	transition: all 1s;
	margin-top: 300px;
	opacity: 0;
}

.box_gd2 {
	margin-top: 160px;
	opacity: 1;
}

.title {
	margin-bottom: 40px;
	font-size: 60px;
	font-weight: 600;
	color: #333333;
	text-align: center;
}



.content {
	text-align: center;
	font-size: 20px;
	color: #555555;
}

.content_left {
	width: 580px;
	line-height: 45px;
	text-align: justify;
	font-size: 32px;
	color: #555555;
	margin-right: 100px;
}

.num div {
	font-size: 66px;
	font-family: Arial-BoldMT, Arial;
	font-weight: 600;
	color: #3F79FE;
	line-height: 76px;
	letter-spacing: 2px;
	text-align: center;
}

.num p {
	font-size: 32px;
	font-family: ArialMT;
	color: #333333;
	line-height: 37px;
	letter-spacing: 1px;
	text-align: center;
}

@media (max-width: 520px) {
	.width{
		min-width: 350px;
	}
	.show{
		display: none;
	}
	.show2{
		display: block;
	}
	.biaoti {
		font-size: 24px;
		color: #fff;
		font-weight: normal;
	}
	
	.biaoti2{
		margin: 20px 0 16px 0;
		font-size: 14px;
		color: #FFFFFF;
	}
	
	.biaoti3{
		margin-bottom: 20px;
		font-size: 14px;
		color: #FFFFFF;
	}
	.biaoti4{
		padding: 7px 20px;
		font-size: 16px;
	}
	.biaoti5{
		padding: 7px 20px;
		font-size: 16px;
	}
	.title {
		margin-bottom: 40px;
		font-size: 24px;
		color: #333333;
	}
	.content {
		font-size: 16px;
	}
	.boxto{
		padding: 60px 0;
	}
}


</style>
