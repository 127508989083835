<template>
    <!-- min-width: 1200px; -->
    <div class="width2">
        <div class="box">
            <div class="box_inp">
                <div class="title">MENU</div>
                    <div @click="tz1('about')">
                        <div class="content">About Us</div>
                        <div class="bord"></div>
                    </div>
                    <div @click="tz2('demand')">
                        <div class="content ">Demand-Side Platform(DSP)</div>
                        <div class="bord"></div>
                    </div>
                    <div @click="tz3('solutions')">
                        <div class="content ">Supply-Side Platform(SSP&Exchange)</div>
                        <div class="bord"></div>
                    </div>
                    <div @click="tz4('partners')">
                        <div class="content">Partners and Cases</div>
                        <div class="bord"></div>
                    </div>
                <div style="cursor: pointer;" @click="dingbu">
                    <div class="content">Log In</div>
                    <div class="bord"></div>
                </div>
                    <div @click="tz5('submit')">
                        <div class="content">Contact Us</div>
                        <div class="bord"></div>
                    </div>
            </div>
            <div class="box_inp">
                <div class="title">TERMS</div>
                <div class="content wid">Privacy Policy</div>
                <div class="bord"></div>
            </div>
            <div class="box_inp">
                <div class="title">SUBSCRIPTION</div>
                <div class="content">Subscribe to our mailing list</div>
                <input type="text" placeholder="email address" v-model="inp"
                    style="outline: none;border: none;font-size: 14px;color: #333;width: 100%;line-height: 30px;background-color: #fff;margin-top: 13px;padding: 0 10px;border-radius: 25px;">
                <div class="but" @click="but">
                    Subscribe</div>
            </div>
        </div>
        <div style="font-size: 13px;color: #fff;text-align: center;padding: 50px 0;">Copyright © 2023 PusHub. All rights
            reserved.</div>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRouter, useRoute } from "vue-router"
const router = useRouter()
const route = useRoute()
let inp = ref('')
let rig = ref(0)
let img = ref(false)
// let xl1 = ref(false)
window.addEventListener('resize', widths, true)
function widths() {
    rig.value = window.innerWidth / 30
    if (window.innerWidth <= 1500) {
        img.value = true
    } else {
        img.value = false
    }
}
// function xlk() {
//     xl1.value = !xl1.value
//     console.log(111);
// }
function dingbu() {
    window.scrollTo(0, 0)
}
function but() {
    router.push({ path: "/subscribe", query: { inp: inp.value } })
}
onMounted(async () => {
    widths()
});

function tz1(e) {
    if (route.meta.name == e) {
        location.reload()
    } else {
        router.push('/about')
    }
}
function tz2(e) {
    if (route.meta.name == e) {
        location.reload()
    } else {
        router.push('/demand')
    }
}
function tz3(e) {
    if (route.meta.name == e) {
        location.reload()
    } else {
        router.push('/solutions')
    }
}
function tz4(e) {
    if (route.meta.name == e) {
        location.reload()
    } else {
        router.push('/partners')
    }
}
function tz5(e) {
    if (route.meta.name == e) {
        location.reload()
    } else {
        router.push('/submit')
    }
}
</script>

<style scoped lang="less">
.box {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto;
}
.box_inp{
    width: 25%;
}
.width2{
    background-color: #3F79FE;
    padding-top: 46px;
    position: relative;
    min-width: 1200px;
}
.title {
    margin-bottom: 40px;
    font-size: 18px;
    font-family: ArialMT;
    color: #FFFFFF;
    line-height: 21px;
}

.content {
    cursor: pointer;
    font-size: 14px;
    font-family: ArialMT;
    color: #FFFFFF;
}

.wid {
    width: 180px;
}

.bord {
    margin: 12px 0;
    height: 1px;
    background-color: #fff;
}

.but {
    transition: all .3s;
    border: 1px solid #FFFFFF;
    border-radius: 21px;
    font-size: 14px;
    color: #fff;
    width: 85px;
    line-height: 30px;
    text-align: center;
    margin-top: 13px;
    cursor: pointer;
}

/* .but:hover {
    background-color: #555;
} */

.xiala1 {
    transition: all .3s;
    height: 0px;
    overflow: hidden;
}

.xiala2 {
    height: 100px;
}

@media (max-width: 520px) {
    .width2{
        min-width: 350px;
    }
    .box {
        display: block;
        padding: 0 24px;
        text-align: left;
    }
    .box_inp{
        width: 100%;
    }
}
</style>
