<template>
	<div class="show" :class="{ 'hello': true }">
		<div class="box_center">
			<div style="display: flex;justify-content:space-between;width: 60%;">
				<div style="cursor: pointer;" @click="shouy">
					<img src="../assets/img_logo_purple.png" alt="">
				</div>
				
				<div class="box" @click="shouy">
					<p class="cw" :style="{ 'color': $route.meta.name == 'home' ? '#3F79FE' : '' }">Home</p>
					<div class="bor" :style="{ 'width': $route.meta.name == 'home' ? '100%' : '' }"></div>
				</div>
				<div class="dh box" style="position: relative;">
					<div @click="tz2('demand')">
						<p class="cw" :style="{ 'color': $route.meta.name == 'solutions' ? '#3F79FE' : $route.meta.name == 'demand' ? '#3F79FE' : '' }">Solutions</p>
						<div class="bor"
							:style="{ 'width': $route.meta.name == 'solutions' ? '100%' : $route.meta.name == 'demand' ? '100%' : '' }">
						</div>
					</div>
					<div class="dhq">
						<div style="border-radius: 0 0 8px 8px;background-color: #fff;padding: 20px 0;;width: 420px;">
							<p class="cw" style="display: flex;justify-content: space-between;padding-top: 16px;padding-bottom: 16px;margin-bottom: 0;" :style="{ 'color': $route.meta.name == 'demand' ? '#3F79FE' : '#666666' }"
								@click="tz2('demand')">
								Demand-Side Platform(DSP) <img class="imgto" style="width: 24px;" src="../assets/images/jiantou.png" alt=""></p>
							<p class="cw" style="display: flex;justify-content: space-between;padding-top: 16px;padding-bottom: 16px;margin-bottom: 0;" :style="{ 'color': $route.meta.name == 'solutions' ? '#3F79FE' : '#666666' }"
								@click="tz3('solutions')">Supply-Side Platform(SSP & Exchange) <img class="imgto" style="width: 24px;" src="../assets/images/jiantou.png" alt=""></p>
						</div>
					</div>
				</div>
				<div class="box" @click="tz4('partners')">
					<p class="cw" :style="{ 'color': $route.meta.name == 'partners' ? '#3F79FE' : '' }">Partners and Cases</p>
					<div class="bor" :style="{ 'width': $route.meta.name == 'partners' ? '100%' : '' }"></div>
				</div>
				<div class="box" @click="tz1('about')">
					<p class="cw" :style="{ 'color': $route.meta.name == 'about' ? '#3F79FE' : '' }">About Us</p>
					<div class="bor" :style="{ 'width': $route.meta.name == 'about' ? '100%' : '' }"></div>
				</div>
			</div>

			<div>
				<button class="button but" style="padding: 6px 26px;cursor: pointer;">Log In</button>
				<div class="dhq" style="padding-top: 20px;">
					<div style="border-radius: 0 0 8px 8px;background-color: #fff;padding: 20px 0;">
						<a href="https://c.adyounger.com/">
							<p class="cw" style="display: flex;justify-content: space-between;padding-top: 16px;padding-bottom: 16px;margin-bottom: 0;" :style="{ 'color': $route.meta.name == 'logdsp' ? '#3F79FE' : '#666666' }"
								>
								Log in SSP & Exchange <img class="imgto" style="width: 24px;" src="../assets/images/jiantou.png" alt=""></p>
						</a>
						<p class="cw" style="display: flex;justify-content: space-between;padding-top: 16px;padding-bottom: 16px;margin-bottom: 0;" :style="{ 'color': $route.meta.name == 'logssp' ? '#3F79FE' : '#666666' }"
							@click="tz6('logdsp')">
							Log in DSP <img class="imgto" style="width: 24px;" src="../assets/images/jiantou.png" alt=""></p>
					</div>
				</div>
				<button class="but2" @click="tz7('submit')" style="cursor: pointer;padding: 6px 24px;">Contact Us</button>
			</div>
		</div>
	</div>


	<!-- <div :class="{'hello5': gd1, 'hello2': gd2, 'hello3': gd3 }" style="background-color: #fff;min-width: 1500px;" v-if="wid">
		<div class="box_center">
			<div style="display: flex;justify-content:space-between;width: 60%;">
				<div style="cursor: pointer;" @click="shouy">
					<img src="../assets/img_logo_purple.png" alt="">
				</div>
				<div class="box" @click="shouy">
					<p class="cw" :style="{ 'color': $route.meta.name == 'home' ? '#3F79FE' : '' }">Home</p>
					<div class="bor" :style="{ 'width': $route.meta.name == 'home' ? '100%' : '' }"></div>
				</div>
				<div class="dh box" style="position: relative;">
					<div @click="tz2('demand')">
						<p class="cw" :style="{ 'color': $route.meta.name == 'solutions' ? '#3F79FE' : $route.meta.name == 'demand' ? '#3F79FE' : '' }">Solutions</p>
						<div class="bor2"
							:style="{ 'width': $route.meta.name == 'solutions' ? '100%' : $route.meta.name == 'demand' ? '100%' : '' }">
						</div>
					</div>
					<div class="dhq">
						<div style="border-radius: 0 0 8px 8px;background-color: #fff;padding: 22px 0;border-top: 2px solid #3F79FE;width: 420px;">
							<p class="cw" style="display: flex;justify-content: space-between;padding-top: 16px;padding-bottom: 16px;margin-bottom: 0;" :style="{ 'color': $route.meta.name == 'demand' ? '#3F79FE' : '#666666' }"
								@click="tz2('demand')">
								Demand-Side Platform(DSP) <img class="imgto" style="width: 24px;" src="../assets/images/jiantou.png" alt=""></p>
							<p class="cw" style="display: flex;justify-content: space-between;padding-top: 16px;padding-bottom: 16px;margin-bottom: 0;" :style="{ 'color': $route.meta.name == 'solutions' ? '#3F79FE' : '#666666' }"
								@click="tz3('solutions')">Supply-Side Platform(SSP & Exchange) <img class="imgto" style="width: 24px;" src="../assets/images/jiantou.png" alt=""></p>
						</div>
					</div>
				</div>
				<div class="box" @click="tz4('partners')">
					<p class="cw" :style="{ 'color': $route.meta.name == 'partners' ? '#3F79FE' : '' }">Partners and Cases</p>
					<div class="bor2" :style="{ 'width': $route.meta.name == 'partners' ? '100%' : '' }"></div>
				</div>
				<div class="box" @click="tz1('about')">
					<p class="cw" :style="{ 'color': $route.meta.name == 'about' ? '#3F79FE' : '' }">About Us</p>
					<div class="bor2" :style="{ 'width': $route.meta.name == 'about' ? '100%' : '' }"></div>
				</div>
			</div>

			<div>
				<button class="button but3" style="padding: 6px 26px;cursor: pointer;">Log In</button>
				<div class="dhq" style="padding-top: 20px;">
					<div style="background-color: #fff;padding: 20px 0;border-radius: 0 0 8px 8px;">
						<a href="https://c.adyounger.com/">
							<p class="cw" style="display: flex;justify-content: space-between;padding-top: 16px;padding-bottom: 16px;margin-bottom: 0;" :style="{ 'color': $route.meta.name == 'logdsp' ? '#3F79FE' : '#666666' }"
								>
								Log in SSP & Exchange <img class="imgto" style="width: 24px;" src="../assets/images/jiantou.png" alt=""></p>
						</a>
						<p class="cw" style="display: flex;justify-content: space-between;padding-top: 16px;padding-bottom: 16px;margin-bottom: 0;" :style="{ 'color': $route.meta.name == 'logdsp' ? '#3F79FE' : '#666666' }"
							@click="tz6('logdsp')">
							Log in DSP <img class="imgto" style="width: 24px;" src="../assets/images/jiantou.png" alt=""></p>
					</div>
				</div>
				<button class="but4" @click="tz7('submit')" style="padding: 6px 24px;cursor: pointer;">Contact Us</button>
			</div>
		</div>
	</div> -->
	<div>

		<div class="show2" :class="{ 'hello6': gd1, 'hello2': gd2, 'hello3': gd3 }" style="padding: 0;width: 100vw;">
			<div style="background-color: #fff;padding: 20px 0;">
				<div class="box_center2" style="position: relative;">
					<div class="carte" @click="cd">
						<div class="menu">
							<img v-if="!cdxs" src="../assets/icon_more.png" style="width: 24px;" alt="">
							<img v-if="cdxs" src="../assets/images/cha.png" style="width: 24px;" alt="">
						</div>
					</div>
					<div style="cursor: pointer;" @click="shouy">
						<div>
							<img class="img" style="width: 120px;" src="../assets/img_logo_purple.png" alt="">
						</div>
					</div>
				</div>
			</div>
			<div :class="{ 'pulldown': true, 'pulldown2': cdxs }">
				<div style="padding: 0 20px;">
					<div class="touch">
						<div @click="shouy">
							<div class="text">Home</div>
							<div class="text_line"></div>
						</div>
					</div>
					<div class="touch">
						<div @click="tz1('about')">
							<div class="text">About Us</div>
							<div class="text_line"></div>
						</div>
					</div>
					<div class="touch">
						<div class="sxlk">
							<div class="text" style="display: flex;justify-content: space-between;">Solutions
								<img :class="{ xz: true, xz2: xz }" src="../assets/icon_return.png" alt="">
							</div>
							<div class="text_line"></div>
						</div>
						<div class="xlk">
							<div class="texts" @click="tz2('demand')" style="padding-top: 10px;">Demand-Side Platform(DSP)</div>
							<div class="texts" @click="tz3('solutions')" style="padding-top: 10px;">Supply-Side Platform(SSP & Exchange)
							</div>
						</div>
					</div>
					<div class="touch">
						<div @click="tz4('partners')">
							<div class="text">Partners and Cases</div>
							<div class="text_line"></div>
						</div>
					</div>
					<div class="touch">
						<div class="sxlk">
							<div class="text" style="display: flex;justify-content: space-between;">Log In<img
									:class="{ xz: true, xz2: xz2 }" src="../assets/icon_return.png" alt=""></div>
							<div class="text_line"></div>
						</div>
						<div class="xlk">
							<a href="https://c.adyounger.com/">
								<div class="texts" style="padding-top: 10px;">Log in SSP & Exchange</div>
							</a>
								<div @click="tz6('logdsp')" class="texts" style="padding-top: 10px;">Log in DSP</div>
						</div>
					</div>
					<div class="touch">
						<div @click="tz7('submit')">
							<div class="text">Contact Us</div>
							<div class="text_line"></div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div @click="onmc" :class="{mc: cdxs}">

		</div>
	</div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRouter, useRoute } from "vue-router"
const router = useRouter()
const route = useRoute()
let p = 0
let t = 0
let gd1 = ref(true)
let gd2 = ref(false)
let gd3 = ref(false)
let wid = ref(true)
let home = ref(false)
let rig = ref(0)
let xz = ref(true)
let xz2 = ref(true)
let cdxs = ref(false)

window.addEventListener('scroll', scrollTop, true)
window.addEventListener('resize', widths, true)


onMounted(async () => {
	scrollTop()
	widths()
});

function cd() {
	cdxs.value = !cdxs.value
}

function shouy(){
	router.push('/')
}

function tz1(e) {
	if (route.meta.name == e) {
		location.reload()
	} else {
		router.push('/about')
	}
}

function tz2(e) {
	if (route.meta.name == e) {
		location.reload()
	} else {
		router.push('/demand')
	}
}
function tz3(e) {
	if (route.meta.name == e) {
		location.reload()
	} else {
		router.push('/solutions')
	}
}


function tz4(e) {
	if (route.meta.name == e) {
		location.reload()
	} else {
		router.push('/partners')
	}
}

// function tz5(e) {
// 	if (route.meta.name == e) {
// 		location.reload()
// 	} else {
// 		router.push('/logdsp')
// 	}
// }
function tz6(e) {
	if (route.meta.name == e) {
		location.reload()
	} else {
		router.push('/logdsp')
	}
}
function tz7(e) {
	if (route.meta.name == e) {
		location.reload()
	} else {
		router.push('/submit')
	}
}


function widths() {
	rig.value = window.innerWidth / 10
	if (window.innerWidth <= 1500) {
		wid.value = true
		// wid.value = true 
	} else {
		if (home.value) {
			wid.value = true
			// wid.value = true
		} else {
			wid.value = true
		}
	}
}



function scrollTop() {
	p = window.pageYOffset
	if (p >= 1000) {
		if (t >= p) {
			gd1.value = false
			gd2.value = true
			gd3.value = false
		} else {
			gd1.value = false
			gd2.value = false
			gd3.value = true
		}
	} else if (window.pageYOffset == 0) {
		gd1.value = true
		gd2.value = false
		gd3.value = false
	}
	if (window.pageYOffset >= 100) {
		home.value = false
	}
	setTimeout(function () { t = p; }, 0);
}


function onmc() {
	cdxs.value = false
}

</script>

<style scoped lang="less">
.mc{
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10;
	width: 100vh;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.5);
}
.menu {
	position: relative;
}
.show{
	min-width: 1200px;
}
.show2{
	display: none;
}
.hello {
	transition: all .3s;
	position: absolute;
	top: 0;
	z-index: 1;
	width: 100%;
	padding: 20px 0;
	background-color: rgba(255, 255, 255, 1);
}

.cw {
	cursor: pointer;
	font-weight: 600;
}

.hello4 {
	/* transition: all .3s; */
	position: absolute;
	top: 0;
	z-index: 100;
	width: 100vw;
	background-color: rgba(255, 255, 255, 1);
}
.hello6 {
	/* transition: all .3s; */
	position: fixed;
	top: 0;
	z-index: 100;
	width: 100vw;
	background-color: rgba(255, 255, 255, 1);
}

.hello2 {
	transition: all .3s;
	position: fixed;
	top: 0;
	z-index: 100;
	width: 100vw;
	padding: 20px 0;
	background-color: #fff;
}

.hello3 {
	position: fixed;
	top: -80px;
	z-index: 100;
	width: 100vw;
	padding: 20px 0;
}

.hello5 {
	position: absolute;
	top: -80px;
	z-index: 1;
	width: 100%;
	padding: 20px 0;
	background-color: rgba(255, 255, 255, 0);
}

.box_center {
	display: flex;
	justify-content: space-between;
	width: 1200px;
	margin: 0 auto;
}

.box_center2 {
	display: flex;
	justify-content: space-between;
	padding: 0 20px;
	margin: 0 auto;
}

.bor {
	transition: all .5s;
	height: 2px;
	width: 0;
	background-color: #3F79FE;
}

.bor2 {
	transition: all .5s;
	height: 2px;
	width: 0;
	background-color: #3F79FE;
}

.box p {
	color: #666666;
}
.box:hover p{
	color: #3F79FE;
}
.box:hover .bor,
.box:hover .bor2 {
	width: 100%;
}


.dhq {
	display: none;
	padding-top: 20px;
	position: absolute;
	width: 300px;
	text-align: center;
}

.dhq2 {
	display: none;
	padding-top: 12px;
	position: absolute;
	width: 300px;
	text-align: center;
}

.dhq div p {
	padding: 0 24px;
	text-align: left;
	color: #333;
}

.dhq div p:hover {
	color: #3F79FE;
	background: rgba(63, 121, 254, 0.08);
}
.dhq div p .imgto{
	display: none;
}
.dhq div p:hover .imgto{
	display: block;
}

.dh:hover .dhq,
.button:hover~.dhq {
	display: block;
}

.dhq:hover {
	display: block;
}

a {
	text-decoration: none;
	font-weight: bold;
	color: #fff;
}

.but {
	margin-right: 20px;
	width: 100px;
	border: 1px solid #3F79FE;
	border-radius: 20px;
	background-color: #3F79FE;
	color: #fff;
}

.but2 {
	/* width: 100px; */
	border: none;
	border-radius: 20px;
	background: rgba(63, 121, 254, 0.0957);
	color: #3F79FE;
}


.but3 {
	margin-right: 20px;
	width: 100px;
	border: 1px solid #3F79FE;
	border-radius: 20px;
	background-color: #3F79FE;
	color: #fff;
}

.but4 {
	/* width: 100px; */
	border: none;
	border-radius: 20px;
	background: rgba(63, 121, 254, 0.0957);
	color: #3F79FE;
}

.pulldown {
	padding-bottom: 20px;
	transition: all .5s;
	position: absolute;
	z-index: -20;
	top: -260px;
	overflow: hidden;
	width: 100%;
	background-color: #fff;
}

.pulldown2 {
	top: 50px;
}


.xl {
	height: 0;
	overflow: hidden;
}

.xlk {
	display: none;
}

.sxlk:hover~.xlk,
.xlk:hover {
	display: block;
}


.xl2 {
	height: 120px;
}

.text {
	/* margin-bottom: 10px; */
	font-size: 14px;
	font-weight: 600;
	color: #555555;
	line-height: 31px;
}

.texts {
	padding: 0 23px;
	font-size: 14px;
	color: #555;
	font-weight: 500;
	line-height: 21px;
}

.texts:hover {
	color: #3F79FE;
}

.text_line {
	transition: all .5s;
	width: 0;
	height: 2px;
	background-color: #3F79FE;
}

.xz {
	transition: all .5s;
	transform: rotate(180deg);
	margin-top: 10px;
	margin-right: 12px;
	width: 15px;
	height: 12px;
}

.xz2 {
	transform: rotate(0deg)
}

.touch {
	cursor: pointer;
	padding-top: 10px;
}

.touch:hover .text_line {
	width: 100%;
}
@media (max-width: 520px) {
	.show{
		display: none;
	}
	.show2{
		display: block;
	}
}
</style>
