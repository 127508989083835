<template>
  <router-view />
</template>

<style lang="less">
#app {
  font-family: style',' Arial-BoldMT, Arial;
  font-size: 16px;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  // color: #2c3e50;
}


</style>
